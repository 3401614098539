var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "landing-div" }, on: { click: _vm.enterToScene } },
      [
        _c("p", { staticClass: "text-center" }, [
          _vm._v("Immersive-AR Web App"),
        ]),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "basicButton", attrs: { id: "xr-button" } },
          [_vm._v("AR not found")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }