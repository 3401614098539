var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "catalogue pb-4" },
    [
      _c("div", { staticClass: "grid grid-cols-3 items-center py-4" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "flex justify-end",
            on: { click: _vm.closeCatalogue },
          },
          [
            _c("img", {
              staticClass: "px-2 py-1",
              attrs: {
                src: "/assets/icons/closeIcon.svg",
                alt: "Icono de cerrar",
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "grid grid-cols-6 gap-4 px-2" },
        _vm._l(_vm.models, function (model) {
          return _c("Model", {
            key: model.id,
            staticClass: "col-span-3",
            attrs: { model: model },
            on: {
              "model-is-loading": _vm.modelIsLoading,
              "model-is-loaded": _vm.modelIsLoaded,
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c("Spinner", { attrs: { showProp: _vm.showSpinner } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-start-2 mx-auto" }, [
      _c("p", [_vm._v("3D Models")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }