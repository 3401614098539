var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "home" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _vm._m(3),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      _vm._m(5),
      _vm._v(" "),
      _vm._m(6),
      _vm._v(" "),
      !_vm.showCatalogue
        ? _c(
            "div",
            { staticClass: "home-menu overlay", attrs: { id: "home-menu" } },
            [
              _c(
                "div",
                {
                  staticClass: "flex-1 text-center py-2",
                  on: { click: _vm.openCatalogue },
                },
                [_vm._m(7)]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            "enter-active-class": "transition ease-out origin-top duration-400",
            "enter-class": "transform opacity-0 scale-90",
            "enter-to-class": "transform opacity-100 scale-100",
            "leave-active-class": "transition origin-top ease-in duration-200",
            "leave-class": "transform opacity-100 scale-100",
            "leave-to-class": "transform opacity-0 scale-90",
          },
        },
        [
          _vm.showCatalogue
            ? _c("Catalogue", {
                attrs: { models: _vm.models },
                on: { "close-catalogue": _vm.closeCatalogue },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { attrs: { id: "targetDotDiv" } }, [
      _c("span", { staticClass: "targetDot" }, [_vm._v(".")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { attrs: { id: "rotateLeftButtonDiv" } }, [
      _c(
        "div",
        {
          staticClass: "rotateActionButton",
          attrs: { id: "rotateLeftButton" },
        },
        [
          _c("img", {
            attrs: {
              src: "/assets/icons/rotateLeft.svg",
              alt: "Rotate left icon",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { attrs: { id: "rotateRightButtonDiv" } }, [
      _c(
        "div",
        {
          staticClass: "rotateActionButton",
          attrs: { id: "rotateRightButton" },
        },
        [
          _c("img", {
            attrs: {
              src: "/assets/icons/rotateRight.svg",
              alt: "Rotate right icon",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "confirmActionButtonDiv",
        attrs: { id: "checkButtonDiv" },
      },
      [
        _c(
          "div",
          { staticClass: "confirmActionButton", attrs: { id: "checkButton" } },
          [
            _c("img", {
              attrs: { src: "/assets/icons/check.svg", alt: "Tick icon" },
            }),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "confirmActionButtonDiv",
        attrs: { id: "trashButtonDiv" },
      },
      [
        _c(
          "div",
          { staticClass: "confirmActionButton", attrs: { id: "trashButton" } },
          [
            _c("img", {
              attrs: { src: "/assets/icons/trash.svg", alt: "Trash icon" },
            }),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "cancelActionButtonDiv",
        attrs: { id: "cancelButtonDiv" },
      },
      [
        _c(
          "div",
          { staticClass: "cancelActionButton", attrs: { id: "cancelButton" } },
          [
            _c("img", {
              attrs: { src: "/assets/icons/closeIcon.svg", alt: "Close icon" },
            }),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "cancelActionButtonDiv",
        attrs: { id: "cancelPlaceModelButtonDiv" },
      },
      [
        _c(
          "div",
          {
            staticClass: "cancelActionButton",
            attrs: { id: "cancelPlaceModelButton" },
          },
          [
            _c("img", {
              attrs: { src: "/assets/icons/closeIcon.svg", alt: "Close icon" },
            }),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("button", [
      _c("img", {
        attrs: { src: "/assets/icons/catalogue.svg", alt: "catalogue icon" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }