var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bg-white" }, [
    _c(
      "div",
      {
        staticClass:
          "h-full border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200",
      },
      [
        _c("div", { staticClass: "h-40" }, [
          _c("img", {
            staticClass: "object-cover shadow-lg rounded-t-lg",
            attrs: {
              src: "/assets/models/" + _vm.model.image,
              alt: _vm.model.name + "image",
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "flex justify-between pt-12 pb-4 px-2",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.prepareModelToDrop.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "h3",
              { staticClass: "text-sm md:text-lg font-medium text-gray-900" },
              [_vm._v(_vm._s(_vm.model.name))]
            ),
            _vm._v(" "),
            _c("img", {
              attrs: { src: "/assets/icons/arkit.svg", alt: "ARkit logo" },
            }),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }