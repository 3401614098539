var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        {
          staticClass:
            "fixed top-0 right-0 h-screen w-screen z-50 flex justify-center items-center",
        },
        [
          _c("div", {
            staticClass:
              "w-12 h-12 border-4 border-teal-600 rounded-full loader",
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }